import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col } from 'react-bootstrap';
import RouteConstants from './components/dealer/RouteConstants';

import Home from './components/home/Home';
import Account from './components/account/Account';
import Records from './components/records/Records';
import Glovebox from './components/glovebox/Glovebox';
import Logo from './components/Logo';
import dealer_logo from './images/beaver.png';
import beaver_logo from './images/BeaverWordLogo.png';

import home from './images/home.svg';
import homeBlackoutLine from './images/home-black-outline.svg';
import records from './images/records.svg';
import glovebox from './images/glovebox.svg';
import account from './images/account.svg';
import accountBlackOutline from './images/account-black-outline.svg';
import gloveboxBlackOutline from './images/glovebox-black-outline.svg';
import recordsBlackOutline from './images/records-black-outline.svg';

import VehicleDetails from './services/vehicle-details/VehicleDetails';
import BottomNavigation from './components/navigation/BottomNavigation';
import DealerDashboard from './components/dealer/DealerDashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import { postEvent } from './services/events/eventApi';

import {
  admin,
  dealer,
} from "./services/account/accountSlice";
import {
  getUser,
} from './services/login/loginSlice';

import { setSelectedRecordPage } from './components/records/recordSlice';
import { setSelectedGloveBoxPage } from './components/glovebox/gloveBoxSlice';

import "./mobile.scss";
import ProvisionVins from './components/dealer/ProvisionedVins';

function App() {
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState("home");
  const [selectedHomeServicePage, setSelectedHomeServicePage ] = useState("buttons");
  const currentUserAdmin = useSelector(admin);
  const currentUserDealer = useSelector(dealer);
  const currentUser = useSelector(getUser);

  const handleHomeSelect = (action) => {
    setSelectedHomeServicePage(action);
  }

  const handleNavClick = (value) => {
    setSelectedPage(value.key);
    setSelectedHomeServicePage("buttons");
    dispatch(setSelectedRecordPage("menu"));
    dispatch(setSelectedGloveBoxPage("menu"));
  }

  const renderSelectedPage = () => {
    if (currentUserDealer) {
      if (selectedPage === RouteConstants.DEALER) {
        return <DealerDashboard />
      } else if (selectedPage === RouteConstants.PROVISION) {
        return <ProvisionVins />
      } else {
        return <DealerDashboard />
      }
    } else if (currentUserAdmin) {
      return <AdminDashboard />
    } else if (selectedPage === "home") {
      return <Home
                selectedHomeServicePage={selectedHomeServicePage} 
                handleHomeSelect={handleHomeSelect}/>
    } else if (selectedPage === RouteConstants.ACCOUNT) {
      return <Account />
    } else if (selectedPage === RouteConstants.RECORDS) {
      return <Records />
    } else if (selectedPage === RouteConstants.GLOVEBOX) {
      return <Glovebox />
    } else {
      return <Home />
    }
  }

  const renderVehicleDetails = () => {
    if (currentUserAdmin || currentUserDealer) {
      return null
    } else {
      return <VehicleDetails />
    }
  }

  const renderDealerLogo = () => {
    if (currentUserAdmin || currentUserDealer) {
      return null
    } else {
      return (
        <div className="dealer-logo">
          <img src={beaver_logo} alt="dealer" />
        </div>
      )
    }
  }

  const dealerNavItems = [
    {
      title: 'Dealer',
      key: 'dealer',
      icon: "D",
      activeIcon: "D",
    },
    {
      title: 'Provisioned VINs',
      key: 'provision',
      icon: "P",
      activeIcon: "P",
    }
  ]

  const bottomNavItems = [
    {
      title: 'Home',
      key: 'home',
      icon: <img src={homeBlackoutLine} alt="home" />,
      activeIcon: <img src={home} alt="home"/>,
    },
    {
      title: 'Records',
      key: 'records',
      icon: <img src={recordsBlackOutline} alt="records" />,
      activeIcon: <img src={records} alt="records" />
    },
    {
      title: 'Glovebox',
      key: 'glovebox',
      icon: <img src={gloveboxBlackOutline} alt="glovebox" />,
      activeIcon: <img src={glovebox} alt="glovebox" />
    },
    {
      title: 'Account',
      key: 'account',
      icon: <img src={accountBlackOutline}  alt="account" />,
      activeIcon: <img src={account} alt="account" />
    }
  ]

  const handleMeetCarrlly = (e) => {
    e.preventDefault();
    postEvent("MEET_CARRLLY", currentUser.email, localStorage.getItem('token'))
    window.open('https://youtu.be/tw1jcacSDe4?si=QFubNLBvhvxNsDXy', '_blank');
  }

  const renderNavigation = () => {
    if ( currentUserDealer ) {
      return (
        <BottomNavigation
          items={dealerNavItems}
          selected={0}
          onItemClick={(item) => handleNavClick(item)}
        />
      )
    } else {
      return (
        <BottomNavigation
          items={bottomNavItems}
          selected={0}
          onItemClick={(item) => handleNavClick(item)}
        />
      )
    }
  }

  return (
    <div>
      {/* <div className='container-navbar'> */}
      <Container>
        <Row>
          <Col xs={9} md={9}>
            <Logo />
          </Col>
          <Col xs={3} md={3} align="end" style={{paddingTop: '.5em'}}>
            <Button variant="outline-primary" size="sm" onClick={handleMeetCarrlly}>Meet Carrlly</Button>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
      <div className="body-container">
        {renderVehicleDetails()} 
        <div className="main-page">
          {renderSelectedPage()}
          {renderDealerLogo()}
        </div>
      </div>
      { renderNavigation() }
    </div>
  )
}

export default App;