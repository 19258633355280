import { useSelector } from "react-redux";
import { format } from "date-fns";
import ec_beaver_logo from "../../images/EC_Beaver.png.webp";
import WarrantyServiceContractImage from "../records/WarrantyServiceContractImage";

function Warranty() {

  const openLearnMore = () => {
    window.open("https://info.easycare.com/pps_beaver-toyota", "_blank");
  }

  return (
    <div className="record-page">
      <div className="record-page-header">
        <div className="record-page-header-text">
          Warranty
        </div>
        <div className="button-image-grey">
          <WarrantyServiceContractImage button-type="record-button-gear"/>
        </div>
      </div>
      <div className="record-page-body">
      </div>
      <div className="record-page-good-news">
        <div className="record-page-logo">
          <img src={ec_beaver_logo} alt="dealer" />
        </div>
        <div className="record-page-good-news-title">
          Good News!
        </div>
        <div className="record-page-good-news-sub-title">
          You're eligible to extend your warranty
        </div>
        <div className="record-page-good-news-body">
          Make sure your vehicle remains protected against costly future repairs. Select from protection and payment options that match your driving and budget needs.
        </div>
      </div>
      <div className="service-page-buttons">
        <button className="button-green" onClick={openLearnMore}>
          <span className="button-green-text">View options</span>
        </button>
      </div>
    </div>
  )
}

export default Warranty;